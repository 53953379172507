/*

    Modal helper functions.

*/

// Loads the given URL into a modal.
// If there's no data it's a GET request,
// otherwise it's a POST request.
function getModal(url, data) {
    if (typeof (data) === 'undefined')
        $(".modal-content").load(url);
    else
        $(".modal-content").load(url, data);
}